import {ApplicationConfig} from '@angular/core';
import {
    provideRouter,
    InMemoryScrollingFeature,
    InMemoryScrollingOptions,
    withInMemoryScrolling
} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideHttpClient} from "@angular/common/http";

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig);
export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes, inMemoryScrollingFeature), provideClientHydration(), provideHttpClient()]
};
